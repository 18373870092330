import React, { useState, useEffect } from "react"

export const useScrollHandler = () => {
    const [scroll, setScroll] = useState(0)

    useEffect(() => {
        const onScroll = () => {
            const scrollCheck = window.scrollY > 175 //TODO magic number
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        }
        document.addEventListener("scroll", onScroll)
        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    }, [scroll, setScroll])
    return scroll
}


export const InPageNavigationCfP = () => {
    const scroll = useScrollHandler()
    const scrollClass = scroll ? "padding-default bg-tertiary stick" : "padding-default bg-tertiary"
    const arrowToTop = scroll ? "↑" : ""
    return (
    <div className=" inpage-nav-container col-sm-3 xs-hide">
        <div className="content-wrapper bg-tertiary mbottom-small padding-20" data-lockfixed="true" className={scrollClass}>
            <h2 className="h2">
                Submission types <span style={{float: "right"}}><a style={{border: 0}}href="#top" >{arrowToTop}</a></span>
            </h2>
            <ul>
                <li><a href="#abstract">Abstract</a></li>
                <li><a href="#poster">Poster presentation</a></li>
                <li><a href="#demo">Live demonstrations</a></li>
                <li><a href="#pitch">Pitch sessions</a></li>
                {/* <li></li>
                <li><a href="#zap">Professors</a></li>
                <li><a href="#student">Master students</a></li> */}
            </ul>
        </div>
    </div>
    )
}

export const InPageNavigationAwards = () => {
    const scroll = useScrollHandler()
    const scrollClass = scroll ? "padding-default bg-tertiary stick" : "padding-default bg-tertiary"
    const arrowToTop = scroll ? "↑" : ""
    return (
    <div className=" inpage-nav-container col-sm-3 xs-hide">
        <div className="content-wrapper bg-tertiary mbottom-small padding-20" data-lockfixed="true" className={scrollClass}>
            <h2 className="h2">
                Prizes
            </h2>
            <ul>
                <li><a href="#audience">Audience</a></li>
                <li><a href="#poster">Best poster</a></li>
                <li><a href="#pitch">Best pitch</a></li>
                <li><a href="#interdisciplinary">Interdisciplinary research</a></li>
                <li><a href="#SDG">Sustainable Development Goals</a></li>
                <li><a href="#impact">Research Impact</a></li>
            </ul>
        </div>
    </div>
    )
}

export const InPageNavigationWorkshops = () => {
    const scroll = useScrollHandler()
    const scrollClass = scroll ? "padding-default bg-tertiary stick" : "padding-default bg-tertiary"
    const arrowToTop = scroll ? "↑" : ""
    return (
    <div className=" inpage-nav-container col-sm-3 xs-hide">
        <div className="content-wrapper bg-tertiary mbottom-small padding-20" data-lockfixed="true"
             className={scrollClass}>
            <h2 className="h2">
                Before the event <span style={{ float: "right" }}><a style={{ border: 0 }} href="#top">{arrowToTop}</a></span>
            </h2>
            <ul>
                <li><a href="#poster">How to develop an academic poster?</a></li>
                <li><a href="#pitch">How to pitch your research?</a></li>
                <li><a href="#questions">How to ask and answer questions?</a></li>
            </ul>
            <h2 className="h2">
                During the event
            </h2>
            <ul>
                <li><a href="#cv">Your PhD in a CV</a></li>
                <li><a href="#valorization">Valorise your research</a></li>
                <li><a href="#starting-phd">Starting a PhD</a></li>
            </ul>
        </div>
    </div>
    )
}

export const InPageNavigationRoundtables = () => {
    const scroll = useScrollHandler()
    const scrollClass = scroll ? "padding-default bg-tertiary stick" : "padding-default bg-tertiary"
    const arrowToTop = scroll ? "↑" : ""
    return (
        <div className=" inpage-nav-container col-sm-3 xs-hide">
            <div className="content-wrapper bg-tertiary mbottom-small padding-20" data-lockfixed="true" className={scrollClass}>
            <h2 className="h2">
                Roundtable companies
            </h2>
            <ul>
                <li><a href="#exail">Exail</a></li>
                <li><a href="#easics">Easics</a></li>
                <li><a href="#barco">Barco</a></li>
                <li><a href="#robovision">Robovision</a></li>
                <li><a href="#multi">MULTI.engineering</a></li>
                <li><a href="#sweco">Sweco</a></li>
                <li><a href="#daikin">Daikin</a></li>
                <li><a href="#arcelor">ArcelorMittal</a></li>
                <li><a href="#melexis">Melexis</a></li>
                <li><a href="#sirus">Sirus</a></li>
            </ul>
        </div>
    </div>
    )
}


export const InPageNavigationSponsoring = () => {
    const scroll = useScrollHandler()
    const scrollClass = scroll ? "padding-default bg-tertiary stick" : "padding-default bg-tertiary"
    const arrowToTop = scroll ? "↑" : ""
    return (
    <div className=" inpage-nav-container col-sm-3 xs-hide">
        <div className="content-wrapper bg-tertiary mbottom-small padding-20" data-lockfixed="true" className={scrollClass}>
            <h2 className="h2">
                Sponsoring FEARS
            </h2>
            <ul>
                <li><a href="#partners">Sponsors</a></li>
                <li><a href="#become-a-sponsor">Become a partner</a></li>
            </ul>
        </div>
    </div>
    )
}

export const InPageNavigation = () => {
    const scroll = useScrollHandler()
    const scrollClass = scroll ? "padding-default bg-tertiary stick" : "padding-default bg-tertiary"
    const arrowToTop = scroll ? "↑" : ""
    return (
    <div className=" inpage-nav-container col-sm-3 xs-hide">
        <div className="content-wrapper bg-tertiary mbottom-small padding-20" data-lockfixed="true" className={scrollClass}>
            <h2 className="h2">
                On this page <span style={{float: "right"}}><a style={{border: 0}}href="#top" >{arrowToTop}</a></span>
            </h2>
            {/* <ul className="linklist linklist-dark"> */}
            <ul>
                <li><a href="#pre-fears">Pre-FEARS workshops</a></li>
                <li><a href="#program">FEARS '23 program</a></li>
                <li><a href="#pitch">Pitch sessions</a></li>
                <li><a href="#poster">Poster sessions</a></li>
            </ul>
        </div>
        {/* <div role="navigation" data-lockfixed="true" className={scrollClass}>
            <h2>
                On this page <span style={{float: "right"}}><a style={{border: 0}}href="#top" >{arrowToTop}</a></span>
            </h2>
            <div id="toc" className="tocify linklist linklist-dark" dangerouslySetInnerHTML={{ __html: toc }}>
            </div>
        </div> */}
    </div>
    )
}


export default InPageNavigation
