import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { InPageNavigationCfP } from "../components/inpage-navigation"


const CallForPaper = () => (
    <Layout>
        <Seo title="Call for papers" />
        <div className="fluid-container">
            <div className="row">
                <InPageNavigationCfP/>
                <article id="portal-column-content" className="cell col-sm-9 mbottom-default">
                    <h1>Call for papers</h1>
                    {/* <div className="alert alert-warning">
                        <strong>EXTENDED DEADLINE </strong>Submissions will be accepted until <strong>October 2nd</strong> <strike>September 25th</strike>.
                    </div> */}
                    <div className="row pbottom-default">
                        <div className="col-sm-7">
                            <p>
                            We invite you to present your research through a poster presentation, live demonstration or short pitch. Why submit?
                                <ul>
                                    <li>🚀 <strong>Showcasing Excellence</strong>: Present your work to an audience of fellow researchers, industry leaders, and experts. </li>
                                    <li>🤝 <strong>Amplify Your Impact</strong>: Gain recognition and expand your influence in your field.</li>
                                    <li>🏆 <strong>Forge New Connections</strong>: Network with like-minded researchers, renowned professors, and industry professionals.</li>
                                    <li>🌟 <strong>Shape The Future</strong>: Influence your domain's advancements.</li>
                                </ul>
                                The submission deadline is <strong>Monday, October 28</strong>!
                            </p>
                            {/* <button className="btn btn-default" href="/" >
                                <a href="https://evan.ugent.be/a/fears2023/" activeClassName="active">Submit your contribution</a>
                            </button> */}
                        </div>
                        <div className="col-sm-5">
                            <p>In addition, FEARS also offers
                            {/* <p>In addition, FEARS also offers hands-on <Link to="/workshops/">workshops</Link>, a plenary panel discussion on <strong>AI in academia</strong> and research, a dedicated session about <strong>starting a PhD</strong> (for master students) or starting a <strong>post-doc</strong> (for PhD researchers) and so much more. */}
                                <ul>
                                    {/* <li>Hands-on <Link to="/workshops/">workshops</Link> in advance.</li> */}
                                    {/* todo: reset link */}
                                    <li>Hands-on workshops in advance.</li>
                                    <li>A plenary panel discussion on <strong>AI in academia</strong> and research.</li>
                                    <li>An information session about <strong>starting a PhD</strong> or <strong>post-doc</strong>.</li>
                                    <li>A closing reception with drinks and appetizers during the an award&nbsp;
                                        {/* <Link to="/awards/">award</Link>.</li>*/}
                                    ceremony.</li>
                                    <li>And so much more...</li>
                                </ul>
                                {/* todo: set program back when dates are final */}
                                {/* Discover the <strong>full <Link to="/program/">program</Link></strong>! */}
                            </p>
                            {/* <button className="btn btn-default" href="/" >
                                <a href="https://evan.ugent.be/r/fears2023/" activeClassName="active">Register now (without submission)</a>
                            </button> */}
                        </div>
                    </div>

                    <div className="pbottom-default" style={{borderTop: "1px solid #ccc"}}></div>
                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h2 id="abstract">
                                Abstract guidelines
                            </h2>
                            <p>All submissions (posters presentations, demos and pitches) must be accompanied by a short abstract.</p>
                        </div>
                        <div className="col-sm-6">
                            <h3>Submission requirements</h3>
                            <ul>
                                <li>A clearly-written UK English abstract is expected.</li>
                                <li>Be concise. Only 200-300 word-long abstracts are accepted.</li>
                                <li>Your title should accurately reflect the content of your work, and thus abstract.</li>
                                <li>Provide a full list of authors (presenting author and co-authors).</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <h3>Abstract requirements</h3>
                            <ul>
                                <li>Your abstract should briefly introduce your field of study, convey your own scientific contributions and assert its scientific relevance.</li>
                                <li>Know your audience.  As people from different departments will be present, make sure your abstract is understandable to a broad engineering crowd.</li>
                                <li>Refrain from referring to previous work. The abstract should be understandable without any additional material.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h3>Review and selection process</h3>
                            <ul>
                                <li>All abstracts will undergo a <strong>light-touch review</strong> process to check the requirements above.</li>
                                <li>You might be asked to <strong>modify</strong> your abstract if it is deemed too technical or unclear.</li>
                                {/* todo: acceptance date */}
                                {/* <li>Notifications regarding <strong>acceptance of rejection</strong> will be communicated no later than <strong>October 9th</strong>.</li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="pbottom-default" style={{borderTop: "1px solid #ccc"}}></div>

                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h2 id="poster">
                                Poster presentation
                            </h2>
                            <p>A poster presentation is a great opportunity to present your research to other members of our faculty as a PhD student or post-doc.</p>
                        </div>
                        <div className="col-sm-6">
                            <h3>Poster requirements</h3>
                            You can present an A0 poster in portrait orientation. You should print the poster yourself and bring it to the venue. We will provide the required materials to set up your poster.
                        </div>
                        <div className="col-sm-6">
                            <h3>Online Proceedings</h3>
                            All submitted posters and abstracts will be included in an online repository, with a unique digital object identifier (<a href="https://www.doi.org/">DOI</a>) per poster. The symposium's proceedings will be made available on the <a href="https://biblio.ugent.be/">Biblio</a> platform.
                        </div>
                    </div>
                    <div className="row pbottom-default">
                        <div className="col-sm-6">
                            <h3>Template for posters</h3>
                            Ghent University provides a <a href="https://styleguide.ugent.be/templates/print.html#poster">template for posters</a>. You are of course welcome to use your own! Need inspiration for a good poster? Then make sure to have a look at this <a href="https://www.youtube.com/watch?v=SYk29tnxASs">outstanding video!</a>
                        </div>
                        <div className="col-sm-6">
                            <h3>Review</h3>
                            Posters will go through a light-touch review process to make sure they meet the requirements. Please also upload your poster digitally before October 28!
                        </div>
                    </div>
                    {/* <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <button className="btn btn-default" href="https://evan.ugent.be/a/fears2023/" >
                                <a href="https://evan.ugent.be/a/fears2023/" activeClassName="active">Submit a poster</a>
                            </button>
                        </div>
                    </div> */}


                    <div className="pbottom-default" style={{borderTop: "1px solid #ccc"}}></div>

                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h2 id="demo">
                                Live demonstrations
                            </h2>
                            <p>A live demo is a great way to engage your audiences. </p>
                        </div>
                        <div className="col-sm-6">
                            <h3>Demo requirements</h3>
                            <ul>
                                <li>Please provide a separate document that describes your setup and required space.</li>
                                <li>Each demo will have a dedicated booth and required space. (Power cords can be requested). Please note that we cannot accommodate areas larger than 3m² per demonstration.</li>
                                {/* <li>Your demo can be accompanied by a poster or a presentation.</li> */}
                                <li>You are also encouraged to submit a poster (see above) to supplement your demonstration.</li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <h3>Online Proceedings </h3>
                            All submitted abstracts will be included in an online repository, with a unique <a href="https://www.doi.org/">DOI</a> per demonstration. The symposium's proceedings will be made available on the UGent <a href="https://biblio.ugent.be/">Biblio</a> website.
                        </div>
                        <div className="col-sm-6">
                            <h3>Review </h3>
                            Demonstrations (abstracts) will go through a light-touch review process to make sure they meet the requirements. Please also upload your supporting poster (if wanted) before October 28!
                        </div>
                    </div>
                    {/* <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <button className="btn btn-default" href="https://evan.ugent.be/a/fears2023/" >
                                <a href="https://evan.ugent.be/a/fears2023/" activeClassName="active">Submit for a demo</a>
                            </button>
                        </div>
                    </div> */}

                    <div className="pbottom-default" style={{borderTop: "1px solid #ccc"}}></div>
                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h2 id="pitch">
                                Pitch details
                            </h2>
                            <p>A pitch is great way to reach a big audience. At FEARS you can exercise your communication skills.</p>
                        </div>
                        <div className="col-sm-6">
                            <h3>Pitch requirements</h3>
                            <ul>
                                <li>You have two minutes to present your research and highlight its importance to the audience.</li>
                                <li>In addition to your abstract, you can use a single slide to support your pitch. If you want to use this slide, it should be submitted alongside your abstract (landscape 16:9, PDF format). </li>
                                <li>While we won't time your pitch, please respect the two-minute limit.</li>
                            </ul>


                            </div>
                        <div className="col-sm-6">
                        <h3>Online Proceedings</h3>
                            <p>All submitted abstracts and slides will be included in an online repository, with a unique <a href="https://www.doi.org/">DOI</a> per pitch. The symposium's proceedings will be made available on the <a href="https://biblio.ugent.be/">Biblio</a> platform.</p>
                        </div>
                    </div>
                    <div className="row pbottom-default">
                        <div className="col-sm-6">
                            <h3>Template for Slides (optional) </h3>
                            <p>Ghent University provides a <a href="https://styleguide.ugent.be/templates/digital.html#presentation-slides">template for slides</a>. You are of course welcome to use your own! </p>
                        </div>
                        <div className="col-sm-6">
                            <h3>Review</h3>
                            Pitches will go through a light-touch review process to make sure they meet the requirements. Please also upload your supporting slide (if wanted) before October 28!
                        </div>
                    </div>
                    {/* <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <button className="btn btn-default" href="https://evan.ugent.be/a/fears2023/" >
                                <a href="https://evan.ugent.be/a/fears2023/" activeClassName="active">Submit for a pitch</a>
                            </button>
                        </div>
                    </div> */}

                </article>
            </div>
        </div>
    </Layout>
)

export default CallForPaper
